import * as MathUtils from "../utils/math.js";
import { UVMapping, ClampToEdgeWrapping, LinearFilter, LinearMipMapLinearFilter, RGBAFormat, UnsignedByteType, LinearEncoding, RepeatWrapping, MirroredRepeatWrapping, CubeReflectionMapping, RGBFormat, NearestFilter, DepthFormat, DepthStencilFormat, UnsignedShortType, UnsignedInt248Type } from "./constants.js";
import { Vector2, Matrix3 } from "../mathtypes.js";
import { EventDispatcher } from "../events.js";

export class Color {
    constructor(r, g, b) {
      Object.defineProperty(this, "isColor", { value: true });

      if (g === undefined && b === undefined) {
        // r is THREE.Color, hex or string
        return this.set(r);
      }

      return this.setRGB(r, g, b);
    }

    set(value) {
      if (value && value.isColor) {
        this.copy(value);
      } else if (typeof value === "number") {
        this.setHex(value);
      } else if ( typeof value === 'string' ) {
        this.setStyle( value );
      }

      return this;
    }

    setScalar(scalar) {
      this.r = scalar;
      this.g = scalar;
      this.b = scalar;

      return this;
    }

    setHex(hex) {
      hex = Math.floor(hex);

      this.r = ((hex >> 16) & 255) / 255;
      this.g = ((hex >> 8) & 255) / 255;
      this.b = (hex & 255) / 255;

      return this;
    }

    setRGB(r, g, b) {
      this.r = r;
      this.g = g;
      this.b = b;

      return this;
    }

    setHSL(h, s, l) {
      // h,s,l ranges are in 0.0 - 1.0
      h = MathUtils.euclideanModulo(h, 1);
      s = MathUtils.clamp(s, 0, 1);
      l = MathUtils.clamp(l, 0, 1);

      if (s === 0) {
        this.r = this.g = this.b = l;
      } else {
        const p = l <= 0.5 ? l * (1 + s) : l + s - l * s;
        const q = 2 * l - p;

        this.r = hue2rgb(q, p, h + 1 / 3);
        this.g = hue2rgb(q, p, h);
        this.b = hue2rgb(q, p, h - 1 / 3);
      }

      return this;
    }

    setStyle(style) {
      function handleAlpha(string) {
        if (string === undefined) return;

        if (parseFloat(string) < 1) {
          console.warn(
            "THREE.Color: Alpha component of " + style + " will be ignored."
          );
        }
      }

      let m;

      if ((m = /^((?:rgb|hsl)a?)\(\s*([^\)]*)\)/.exec(style))) {
        // rgb / hsl

        let color;
        const name = m[1];
        const components = m[2];

        switch (name) {
          case "rgb":
          case "rgba":
            if (
              (color =
                /^(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(
                  components
                ))
            ) {
              // rgb(255,0,0) rgba(255,0,0,0.5)
              this.r = Math.min(255, parseInt(color[1], 10)) / 255;
              this.g = Math.min(255, parseInt(color[2], 10)) / 255;
              this.b = Math.min(255, parseInt(color[3], 10)) / 255;

              handleAlpha(color[4]);

              return this;
            }

            if (
              (color =
                /^(\d+)\%\s*,\s*(\d+)\%\s*,\s*(\d+)\%\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(
                  components
                ))
            ) {
              // rgb(100%,0%,0%) rgba(100%,0%,0%,0.5)
              this.r = Math.min(100, parseInt(color[1], 10)) / 100;
              this.g = Math.min(100, parseInt(color[2], 10)) / 100;
              this.b = Math.min(100, parseInt(color[3], 10)) / 100;

              handleAlpha(color[4]);

              return this;
            }

            break;

          case "hsl":
          case "hsla":
            if (
              (color =
                /^(\d*\.?\d+)\s*,\s*(\d+)\%\s*,\s*(\d+)\%\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(
                  components
                ))
            ) {
              // hsl(120,50%,50%) hsla(120,50%,50%,0.5)
              const h = parseFloat(color[1]) / 360;
              const s = parseInt(color[2], 10) / 100;
              const l = parseInt(color[3], 10) / 100;

              handleAlpha(color[4]);

              return this.setHSL(h, s, l);
            }

            break;
          default:
            break;
        }
      } else if ((m = /^\#([A-Fa-f\d]+)$/.exec(style))) {
        // hex color

        const hex = m[1];
        const size = hex.length;

        if (size === 3) {
          // #ff0
          this.r = parseInt(hex.charAt(0) + hex.charAt(0), 16) / 255;
          this.g = parseInt(hex.charAt(1) + hex.charAt(1), 16) / 255;
          this.b = parseInt(hex.charAt(2) + hex.charAt(2), 16) / 255;

          return this;
        } else if (size === 6) {
          // #ff0000
          this.r = parseInt(hex.charAt(0) + hex.charAt(1), 16) / 255;
          this.g = parseInt(hex.charAt(2) + hex.charAt(3), 16) / 255;
          this.b = parseInt(hex.charAt(4) + hex.charAt(5), 16) / 255;

          return this;
        }
      }

      return this;
    }

    clone() {
      return new this.constructor(this.r, this.g, this.b);
    }

    copy(color) {
      this.r = color.r;
      this.g = color.g;
      this.b = color.b;

      return this;
    }

    copyGammaToLinear(color, gammaFactor = 2.0) {
      this.r = Math.pow(color.r, gammaFactor);
      this.g = Math.pow(color.g, gammaFactor);
      this.b = Math.pow(color.b, gammaFactor);

      return this;
    }

    copyLinearToGamma(color, gammaFactor = 2.0) {
      const safeInverse = gammaFactor > 0 ? 1.0 / gammaFactor : 1.0;

      this.r = Math.pow(color.r, safeInverse);
      this.g = Math.pow(color.g, safeInverse);
      this.b = Math.pow(color.b, safeInverse);

      return this;
    }

    convertGammaToLinear(gammaFactor) {
      this.copyGammaToLinear(this, gammaFactor);

      return this;
    }

    convertLinearToGamma(gammaFactor) {
      this.copyLinearToGamma(this, gammaFactor);

      return this;
    }

    copySRGBToLinear(color) {
      this.r = SRGBToLinear(color.r);
      this.g = SRGBToLinear(color.g);
      this.b = SRGBToLinear(color.b);

      return this;
    }

    copyLinearToSRGB(color) {
      this.r = LinearToSRGB(color.r);
      this.g = LinearToSRGB(color.g);
      this.b = LinearToSRGB(color.b);

      return this;
    }

    convertSRGBToLinear() {
      this.copySRGBToLinear(this);

      return this;
    }

    convertLinearToSRGB() {
      this.copyLinearToSRGB(this);

      return this;
    }

    getHex() {
      return (
        ((this.r * 255) << 16) ^ ((this.g * 255) << 8) ^ ((this.b * 255) << 0)
      );
    }

    getHexString() {
      return ("000000" + this.getHex().toString(16)).slice(-6);
    }

    getHSL(target) {
      // h,s,l ranges are in 0.0 - 1.0
      const r = this.r,
        g = this.g,
        b = this.b;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);

      let hue, saturation;
      const lightness = (min + max) / 2.0;

      if (min === max) {
        hue = 0;
        saturation = 0;
      } else {
        const delta = max - min;

        saturation =
          lightness <= 0.5 ? delta / (max + min) : delta / (2 - max - min);

        switch (max) {
          case r:
            hue = (g - b) / delta + (g < b ? 6 : 0);
            break;
          case g:
            hue = (b - r) / delta + 2;
            break;
          case b:
            hue = (r - g) / delta + 4;
            break;
          default:
            break;
        }

        hue /= 6;
      }

      target.h = hue;
      target.s = saturation;
      target.l = lightness;

      return target;
    }

    getStyle() {
      return (
        "rgb(" +
        ((this.r * 255) | 0) +
        "," +
        ((this.g * 255) | 0) +
        "," +
        ((this.b * 255) | 0) +
        ")"
      );
    }

    offsetHSL(h, s, l) {
      this.getHSL(_hslA);

      _hslA.h += h;
      _hslA.s += s;
      _hslA.l += l;

      this.setHSL(_hslA.h, _hslA.s, _hslA.l);

      return this;
    }

    add(color) {
      this.r += color.r;
      this.g += color.g;
      this.b += color.b;

      return this;
    }

    addColors(color1, color2) {
      this.r = color1.r + color2.r;
      this.g = color1.g + color2.g;
      this.b = color1.b + color2.b;

      return this;
    }

    addScalar(s) {
      this.r += s;
      this.g += s;
      this.b += s;

      return this;
    }

    sub(color) {
      this.r = Math.max(0, this.r - color.r);
      this.g = Math.max(0, this.g - color.g);
      this.b = Math.max(0, this.b - color.b);

      return this;
    }

    multiply(color) {
      this.r *= color.r;
      this.g *= color.g;
      this.b *= color.b;

      return this;
    }

    multiplyScalar(s) {
      this.r *= s;
      this.g *= s;
      this.b *= s;

      return this;
    }

    lerp(color, alpha) {
      this.r += (color.r - this.r) * alpha;
      this.g += (color.g - this.g) * alpha;
      this.b += (color.b - this.b) * alpha;

      return this;
    }

    lerpHSL(color, alpha) {
      this.getHSL(_hslA);
      color.getHSL(_hslB);

      const h = MathUtils.lerp(_hslA.h, _hslB.h, alpha);
      const s = MathUtils.lerp(_hslA.s, _hslB.s, alpha);
      const l = MathUtils.lerp(_hslA.l, _hslB.l, alpha);

      this.setHSL(h, s, l);

      return this;
    }

    equals(c) {
      return c.r === this.r && c.g === this.g && c.b === this.b;
    }

    fromArray(array, offset = 0) {
      this.r = array[offset];
      this.g = array[offset + 1];
      this.b = array[offset + 2];

      return this;
    }

    toArray(array = [], offset = 0) {
      array[offset] = this.r;
      array[offset + 1] = this.g;
      array[offset + 2] = this.b;

      return array;
    }

    fromBufferAttribute(attribute, index) {
      this.r = attribute.getX(index);
      this.g = attribute.getY(index);
      this.b = attribute.getZ(index);

      if (attribute.normalized === true) {
        // assuming Uint8Array

        this.r /= 255;
        this.g /= 255;
        this.b /= 255;
      }

      return this;
    }
  }

  Color.prototype.r = 1;
  Color.prototype.g = 1;
  Color.prototype.b = 1;

  const _hslA = { h: 0, s: 0, l: 0 };
  const _hslB = { h: 0, s: 0, l: 0 };

  export function hue2rgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * 6 * (2 / 3 - t);
    return p;
  }

  export function SRGBToLinear(c) {
    return c < 0.04045
      ? c * 0.0773993808
      : Math.pow(c * 0.9478672986 + 0.0521327014, 2.4);
  }

  export function LinearToSRGB(c) {
    return c < 0.0031308 ? c * 12.92 : 1.055 * Math.pow(c, 0.41666) - 0.055;
  }

  let textureId = 0;

export class Texture extends EventDispatcher {
  isTexture = true;

  offset = new Vector2(0, 0);
  repeat = new Vector2(1, 1);
  center = new Vector2(0, 0);
  rotation = 0;

  matrixAutoUpdate = true;
  matrix = new Matrix3();

  generateMipmaps = true;
  premultiplyAlpha = false;
  flipY = true;
  unpackAlignment = 4; // valid values: 1, 2, 4, 8 (see http://www.khronos.org/opengles/sdk/docs/man/xhtml/glPixelStorei.xml)

  version = 0;
  onUpdate = null;

  constructor(
      image = Texture.DEFAULT_IMAGE,
      mapping = Texture.DEFAULT_MAPPING,
      wrapS = ClampToEdgeWrapping,
      wrapT = ClampToEdgeWrapping,
      magFilter = LinearFilter,
      minFilter = LinearMipMapLinearFilter,
      format = RGBAFormat,
      type = UnsignedByteType,
      anisotropy = 1,
      encoding = LinearEncoding
    ) {
      super();
      this.id = textureId++;
      this.uuid = MathUtils.generateUUID();

      this.name = "";

      this.image = image;
      this.mipmaps = [];

      this.mapping = mapping;

      this.wrapS = wrapS;
      this.wrapT = wrapT;

      this.magFilter = magFilter;
      this.minFilter = minFilter;

      this.anisotropy = anisotropy;

      this.format = format;
      this.internalFormat = null;
      this.type = type;

      // Values of encoding !== THREE.LinearEncoding only supported on map, envMap and emissiveMap.
      //
      // Also changing the encoding after already used by a Material will not automatically make the Material
      // update. You need to explicitly call Material.needsUpdate to trigger it to recompile.
      this.encoding = encoding;
    }

    updateMatrix() {
      this.matrix.setUvTransform(
        this.offset.x,
        this.offset.y,
        this.repeat.x,
        this.repeat.y,
        this.rotation,
        this.center.x,
        this.center.y
      );
    }

    clone() {
      return new this.constructor().copy(this);
    }

    copy(source) {
      this.name = source.name;

      this.image = source.image;
      this.mipmaps = source.mipmaps.slice(0);

      this.mapping = source.mapping;

      this.wrapS = source.wrapS;
      this.wrapT = source.wrapT;

      this.magFilter = source.magFilter;
      this.minFilter = source.minFilter;

      this.anisotropy = source.anisotropy;

      this.format = source.format;
      this.internalFormat = source.internalFormat;
      this.type = source.type;

      this.offset.copy(source.offset);
      this.repeat.copy(source.repeat);
      this.center.copy(source.center);
      this.rotation = source.rotation;

      this.matrixAutoUpdate = source.matrixAutoUpdate;
      this.matrix.copy(source.matrix);

      this.generateMipmaps = source.generateMipmaps;
      this.premultiplyAlpha = source.premultiplyAlpha;
      this.flipY = source.flipY;
      this.unpackAlignment = source.unpackAlignment;
      this.encoding = source.encoding;

      return this;
    }

    dispose() {
      this.dispatchEvent({ type: "dispose" });
    }

    transformUv(uv) {
      if (this.mapping !== UVMapping) return uv;

      uv.applyMatrix3(this.matrix);

      if (uv.x < 0 || uv.x > 1) {
        switch (this.wrapS) {
          case RepeatWrapping:
            uv.x = uv.x - Math.floor(uv.x);
            break;

          case ClampToEdgeWrapping:
            uv.x = uv.x < 0 ? 0 : 1;
            break;

          case MirroredRepeatWrapping:
            if (Math.abs(Math.floor(uv.x) % 2) === 1) {
              uv.x = Math.ceil(uv.x) - uv.x;
            } else {
              uv.x = uv.x - Math.floor(uv.x);
            }

            break;
          default:
            break;
        }
      }

      if (uv.y < 0 || uv.y > 1) {
        switch (this.wrapT) {
          case RepeatWrapping:
            uv.y = uv.y - Math.floor(uv.y);
            break;

          case ClampToEdgeWrapping:
            uv.y = uv.y < 0 ? 0 : 1;
            break;

          case MirroredRepeatWrapping:
            if (Math.abs(Math.floor(uv.y) % 2) === 1) {
              uv.y = Math.ceil(uv.y) - uv.y;
            } else {
              uv.y = uv.y - Math.floor(uv.y);
            }

            break;
          default:
            break;
        }
      }

      if (this.flipY) {
        uv.y = 1 - uv.y;
      }

      return uv;
    }
}

  Texture.DEFAULT_IMAGE = undefined;
  Texture.DEFAULT_MAPPING = UVMapping;

  Object.defineProperty(Texture.prototype, "needsUpdate", {
    set: function (value) {
      if (value === true) this.version++;
    },
  });


export class CubeTexture extends Texture {
  isCubeTexture = true;

  constructor(images,
    mapping,
    wrapS,
    wrapT,
    magFilter,
    minFilter,
    format,
    type,
    anisotropy,
    encoding) {
    super(images ?? [], mapping ?? CubeReflectionMapping, wrapS, wrapT, magFilter, minFilter, format ?? RGBFormat, type, anisotropy, encoding);
    images = images ?? [];
    mapping = mapping ?? CubeReflectionMapping;
    format = format ?? RGBFormat;

    this.flipY = false;

    // Why CubeTexture._needsFlipEnvMap is necessary:
    //
    // By convention -- likely based on the RenderMan spec from the 1990's -- cube maps are specified by WebGL (and three.js)
    // in a coordinate system in which positive-x is to the right when looking up the positive-z axis -- in other words,
    // in a left-handed coordinate system. By continuing this convention, preexisting cube maps continued to render correctly.
    // three.js uses a right-handed coordinate system. So environment maps used in three.js appear to have px and nx swapped
    // and the flag _needsFlipEnvMap controls this conversion. The flip is not required (and thus _needsFlipEnvMap is set to false)
    // when using WebGLCubeRenderTarget.texture as a cube texture.
    this._needsFlipEnvMap = true;
  }
}

Object.defineProperty(CubeTexture.prototype, "images", {
  get: function () {
      return this.image;
  },

  set: function (value) {
      this.image = value;
  },
});

export class CompressedTexture extends Texture {
  isCompressedTexture = true;

  constructor(mipmaps, width, height, format, type, mapping, wrapS, wrapT, magFilter, minFilter, anisotropy, encoding) {
    super(null, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy, encoding);

    this.image = { width: width, height: height };
    this.mipmaps = mipmaps;

    // no flipping for cube textures
    // (also flipping doesn't work for compressed textures )
    this.flipY = false;

    // can't generate mipmaps for compressed textures
    // mips must be embedded in DDS files
    this.generateMipmaps = false;
  }
}

export class CanvasTexture extends Texture {
  isCanvasTexture = true;

  constructor(canvas, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy) {
    super(canvas, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy );

    this.needsUpdate = true;
  }
}

export class DepthTexture extends Texture {
  isDepthTexture = true;

  constructor(width, height, type, mapping, wrapS, wrapT, magFilter, minFilter, anisotropy, format) {
    format = format ?? DepthFormat;

    if (format !== DepthFormat && format !== DepthStencilFormat) {
      throw new Error(
        "DepthTexture format must be either THREE.DepthFormat or THREE.DepthStencilFormat"
      );
    }

    if (type === undefined && format === DepthFormat)
      type = UnsignedShortType;
    if (type === undefined && format === DepthStencilFormat)
      type = UnsignedInt248Type;

    super(null, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy );

    this.image = { width: width, height: height };

    this.magFilter = magFilter ?? NearestFilter;
    this.minFilter = minFilter ?? NearestFilter;

    this.flipY = false;
    this.generateMipmaps = false;
  }
}