/*
*   React friendly worker creation from https://github.com/facebook/create-react-app/issues/1277#issuecomment-345516463
*/
export class WebWorker {
    constructor(worker) {
        let code = worker.toString();
        code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

        const blob = new Blob([code], { type: "application/javascript" });
        return new Worker(URL.createObjectURL(blob));
    }
}

class WorkerPool {
    constructor() {
        this.workers = {};
    }

    getWorker(in_worker) {
        if (!this.workers[in_worker]) {
            this.workers[in_worker] = [];
        }

        if (this.workers[in_worker].length === 0) {
            let worker = new Worker(in_worker, { type: "module" });
            this.workers[in_worker].push(worker);
        }

        let worker = this.workers[in_worker].pop();

        return worker;
    }

    returnWorker(worker_type, worker) {
        this.workers[worker_type].push(worker);
    }
}

export const workerPool = new WorkerPool();