import * as Shaders from "../rendering/shaders.js";

export class SQCache {
    #shaderCache = new Map();
    #bufferCache = new Map();

    getProgram(gl, vert, frag) {
      const key = vert + " " + frag;

      const entry = this.#shaderCache.get(key);
      if(!!entry) {
        return entry;
      }

      const program = gl.createProgram();

      const VERTEX_SHADER_TYPE = 35633;
      const FRAGMENT_SHADER_TYPE = 35632;

      const vertexShaderString = Shaders.get(vert);
      const fragmentShaderString = Shaders.get(frag);

      const vertexShader = this.#createShader(gl, VERTEX_SHADER_TYPE, vertexShaderString);
      const fragmentShader = this.#createShader(gl, FRAGMENT_SHADER_TYPE, fragmentShaderString);

      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);

      gl.linkProgram(program);

      this.#checkDiagnostics(gl, program, vertexShader, fragmentShader);

      // Clean up
      gl.deleteShader(vertexShader);
      gl.deleteShader(fragmentShader);

      this.#shaderCache.set(key, program);

      return program;
    }

    #createShader(gl, type, string) {
      const shader = gl.createShader(type);

      gl.shaderSource(shader, string);
      gl.compileShader(shader);

      return shader;
    }

    #checkDiagnostics(gl, program, vertexShader, fragmentShader) {
      const programLog = gl.getProgramInfoLog(program).trim();

      if (gl.getProgramParameter(program, 35714) === false) {
        const vertexErrors = getShaderErrors(gl, vertexShader, "vertex");
        const fragmentErrors = getShaderErrors(gl, fragmentShader, "fragment");

        console.error(
          "Shader error: ",
          gl.getError(),
          "\n[35715]:\n",
          gl.getProgramParameter(program, 35715),
          "\n[Log]:\n",
          programLog,
          "\n[Vertex Errors]:\n",
          vertexErrors,
          "\n[Fragment Errors]:\n",
          fragmentErrors
        );
      } else if (programLog !== "") {
        console.warn("gl.getProgramInfoLog()", programLog);
      }
    }

    getBuffer(gl, geometry) {
      const entry = this.#bufferCache.get(geometry.uuid);
      if(!!entry) {
        return entry;
      }

      const buffer = geometry.createRenderBuffer(gl);

      this.#bufferCache.set(geometry.uuid, buffer);

      return buffer;
    }
}

function getShaderErrors(gl, shader, type) {
    const status = gl.getShaderParameter(shader, 35713);
    const log = gl.getShaderInfoLog(shader).trim();

    if (status && log === "") return "";

    // --enable-privileged-webgl-extension
    const source = gl.getShaderSource(shader);

    return (
      "THREE.WebGLShader: gl.getShaderInfoLog() " +
      type +
      "\n" +
      log +
      addLineNumbers(source)
    );
}

function addLineNumbers(string) {
    const lines = string.split("\n");

    for (let i = 0; i < lines.length; i++) {
        lines[i] = i + 1 + ": " + lines[i];
    }

    return lines.join("\n");
}
