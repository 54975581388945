import { IconButton, MenuItem, Select, Tab, Tabs, TextField } from "@mui/material";
import React from "react";
import { evaluateRules, findUsedClasses } from "utils/ruleEngine";

export const OverlayMenus = {
    NONE: 0,
    CLEARANCE: 1,
}

class OverlayHeader extends React.Component {
    render() {
        return <div className="overlay-header">
            <h6>{this.props.title}</h6>
            <IconButton onClick={this.props.onClose}>
                <svg viewBox="0 0 24 24" style={{width: '1em', height: '1em'}}>
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                </svg>
            </IconButton>
        </div>
    }
}

// Rendering constants.
const SQUARE_SIDE_PIXELS = 60;
const HALF_SQUARE_PIXELS = SQUARE_SIDE_PIXELS/2;
const POWERLINE_RADIUS = SQUARE_SIDE_PIXELS/3;

class ClearanceSystemMenu extends React.Component {
    #selectedSquare = null;
    #grid = null;
    #linePoints = [];
    #outerPhase = [];

    #width = 0;
    #height = 0;
    #width_squares = 0;
    #height_squares = 0;

    constructor(props) {
        super(props);

        this.state = {
            displayed_tab: 0,
            classification: 1,
            square_side_meters: 1.0
        };
    }

    componentDidMount() {
        const canvas = document.getElementById("clearance_canvas");
        canvas.addEventListener("mouseenter", this.onMouseEnter);
        canvas.addEventListener("mousemove", this.onMouseEnter);
        canvas.addEventListener("mouseleave", this.onMouseLeave);

        this.#width = canvas.width;
        this.#height = canvas.height;

        this.#width_squares = this.#width / SQUARE_SIDE_PIXELS + 1;
        this.#height_squares = this.#height / SQUARE_SIDE_PIXELS + 1;

        this.#grid = Array.from({ length: this.#width_squares * this.#height_squares });

        const poleWidth = SQUARE_SIDE_PIXELS * 4;
        const poleHeight = SQUARE_SIDE_PIXELS * 2;

        this.#linePoints = this.getLinePoints(this.#width, poleWidth, this.#height, poleHeight);
        this.#outerPhase = this.findOuterPhase(this.#linePoints);

        this.#evaulateRulesForGrid(this.#grid, this.#linePoints, this.#width_squares, this.#height_squares);
        this.#render(canvas);
    }

    componentDidUpdate() {
        const canvas = document.getElementById("clearance_canvas");
        this.#evaulateRulesForGrid(this.#grid, this.#linePoints, this.#width_squares, this.#height_squares);
        this.#render(canvas);
    }

    componentWillUnmount() {
        const canvas = document.getElementById("clearance_canvas");
        canvas.removeEventListener("mouseenter", this.onMouseEnter);
        canvas.removeEventListener("mousemove", this.onMouseEnter);
        canvas.removeEventListener("mouseleave", this.onMouseLeave);
    }

    #render(canvas) {
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, this.#width, this.#height);

        ctx.lineWidth = 2;
        this.#drawGrid(ctx, this.#grid, this.#width_squares, this.#height_squares);

        //ctx.lineWidth = 4;
        //this.#drawOuterPhase(ctx, this.#height);

        if(!!this.#selectedSquare) {
            this.drawSelectedSquareInfo(ctx);
        }

        ctx.lineWidth = 8;
        this.#drawPole(ctx, this.#width, this.#height, this.#linePoints);

        this.#drawPowerlines(ctx, this.#linePoints);
    }

    onMouseEnter = (event) => {
        const canvas = document.getElementById("clearance_canvas");
        const widthScale = canvas.width / canvas.clientWidth;
        const heightScale = canvas.height / canvas.clientHeight;

        this.#selectedSquare = [
            Math.round(event.offsetX * widthScale  / SQUARE_SIDE_PIXELS),
            Math.round(event.offsetY * heightScale / SQUARE_SIDE_PIXELS)
        ];
        this.#render(canvas);
    }

    onMouseLeave = () => {
        this.#selectedSquare = null;

        const canvas = document.getElementById("clearance_canvas");
        this.#render(canvas);
    }

    drawSelectedSquareInfo(ctx) {
        ctx.lineWidth = 6;
        ctx.strokeStyle = "black";

        // Square outline.
        ctx.setLineDash([10, 5]);
        const x = this.#selectedSquare[0];
        const y = this.#selectedSquare[1];
        const x_pixels = x * SQUARE_SIDE_PIXELS;
        const y_pixels = y * SQUARE_SIDE_PIXELS;
        ctx.strokeRect(x_pixels - HALF_SQUARE_PIXELS, y_pixels - HALF_SQUARE_PIXELS, SQUARE_SIDE_PIXELS, SQUARE_SIDE_PIXELS);
        ctx.strokeStyle = "black";
        ctx.setLineDash([]);

        // Square value text.
        const value = this.#grid[y * this.#width_squares + x];
        if (!!value) {
            ctx.textAlign = "right";
            ctx.font = "60px Manrope, sans-serif";

            const text = "" + value;
            const text_measured = ctx.measureText(text);

            const padding = 6;

            ctx.lineWidth = 2;
            ctx.fillRect(this.#width - text_measured.width - padding, this.#height - 55, text_measured.width + padding, 55);
            ctx.strokeRect(this.#width - text_measured.width - padding, this.#height - 55, text_measured.width + padding, 55);

            ctx.fillStyle = "black";
            ctx.fillText(text, this.#width - padding / 2, this.#height - 5);
            ctx.fillStyle = "white";
        }

        // Distance from powerline.
        let closestPowerline = this.#linePoints[0];
        let closestPowerlineDistance = Infinity;

        for(const point of this.#linePoints) {
            const x_dist = Math.abs(x - point[0] / SQUARE_SIDE_PIXELS) * this.state.square_side_meters;
            const y_dist = Math.abs(y - point[1] / SQUARE_SIDE_PIXELS) * this.state.square_side_meters;

            const distance = (x_dist**2 + y_dist**2);
            if(distance < closestPowerlineDistance) {
                closestPowerline = point;
                closestPowerlineDistance = distance;
            }
        }

        // Clamp line to edges of square
        let close_x = x_pixels;
        if (closestPowerline[0] < close_x) {
            close_x -= HALF_SQUARE_PIXELS;
        } else if(closestPowerline[0] > close_x) {
            close_x += HALF_SQUARE_PIXELS
        }

        let close_y = y_pixels;
        if (closestPowerline[1] < close_y) {
            close_y -= HALF_SQUARE_PIXELS;
        } else if (closestPowerline[1] > close_y) {
            close_y += HALF_SQUARE_PIXELS
        }

        ctx.strokeStyle = "silver";
        ctx.lineWidth = 4;
        ctx.setLineDash([10, 5]);
        ctx.beginPath();
        ctx.moveTo(closestPowerline[0], closestPowerline[1]);
        ctx.lineTo(close_x, close_y);
        ctx.stroke();
        ctx.setLineDash([]);
        ctx.strokeStyle = "black";

        ctx.font = "40px Manrope, sans-serif";
        ctx.textAlign = "center";

        // Move text below if we are below powerline.
        const textY = y_pixels + (closestPowerline[1] > y_pixels ? -(HALF_SQUARE_PIXELS + 12) : (HALF_SQUARE_PIXELS + 38));

        ctx.fillStyle = "black";
        ctx.fillText(Math.sqrt(closestPowerlineDistance).toFixed(2) + " m", x_pixels, textY);
        ctx.fillStyle = "white";
    }

    getLinePoints(width, poleWidth, height, poleHeight) {
        return [
            [width / 2 - poleWidth, height / 2],
            [width / 2 - SQUARE_SIDE_PIXELS * 2, height / 2 - poleHeight],
            [width / 2 + SQUARE_SIDE_PIXELS * 2, height / 2 - poleHeight],
            [width / 2 + poleWidth, height / 2]
        ];
    }
    findOuterPhase(linePoints) {
        let min_phase = linePoints[0];
        let max_phase = min_phase;

        for(const point of linePoints) {
            if(point[0] < min_phase[0]) {
                min_phase = point;
            }
            if(point[0] > max_phase[0]) {
                max_phase = point;
            }
        }

        return [min_phase, max_phase];
    }

    #drawPowerlines(ctx, linePoints) {
        linePoints.forEach(([x, y]) => {
            ctx.beginPath();
            ctx.ellipse(x, y, POWERLINE_RADIUS, POWERLINE_RADIUS, 0, 0, 360, false);
            ctx.fill();
            ctx.stroke();
        });
    }

    #evaulateRulesForGrid(grid, linePoints, grid_width, grid_height) {
        const rules = window.viewer.clearance_rules;

        if(!!rules.rules) {
            const linePointsInGrid = linePoints.map(([x, y]) => [(x / SQUARE_SIDE_PIXELS), (y / SQUARE_SIDE_PIXELS)]);
            const squareClass = this.state.classification;

            for(let x = 0; x < grid_width; x++) {
                for(let y = 0; y < grid_height; y++) {
                    grid[y * grid_width + x] = undefined;
                }
            }

            for(let x = 0; x < grid_width; x++) {
                for(let y = 0; y < grid_height; y++) {
                    const actions = evaluateRules(rules, x, y, linePointsInGrid, squareClass, this.state.square_side_meters);

                    actions.forEach(action => {
                        if(action["name"] === "set_infringement_class") {
                            grid[y * grid_width + x] = action["params"]["value"];
                        }
                    });
                }
            }
        }
    }

    #drawPole(ctx, width, height, linePoints) {
        // Pole
        ctx.beginPath();
        ctx.moveTo(width/2, height);
        ctx.lineTo(width/2, height/2);
        ctx.stroke();

        // Line holding.
        ctx.beginPath();
        ctx.moveTo(linePoints[0][0], linePoints[0][1]);
        for (let i = 1; i < linePoints.length; i++) {
            ctx.lineTo(linePoints[i][0], linePoints[i][1]);

        }
        ctx.closePath();
        ctx.stroke();
    }

    #drawGrid(ctx, grid, squares_wide, squares_tall) {
        const SQUARES_WIDE = squares_wide;
        const SQUARES_TALL = squares_tall;

        for(let x = 0; x < SQUARES_WIDE; x++) {
            for(let y = 0; y < SQUARES_TALL; y++) {
                const grid_state = grid[y * SQUARES_WIDE + x];
                if(grid_state === undefined || grid_state === 0) {
                    ctx.fillStyle = "white";
                } else {
                    const style = Object.values(window.viewer.sceneData.clearance_system.axes)[0].colors[grid_state];
                    ctx.fillStyle = style;
                }

                ctx.fillRect(x*SQUARE_SIDE_PIXELS - HALF_SQUARE_PIXELS, y*SQUARE_SIDE_PIXELS - HALF_SQUARE_PIXELS, SQUARE_SIDE_PIXELS, SQUARE_SIDE_PIXELS);
            }
        }
    }

    render() {
        const rules = window.viewer.clearance_rules;

        // Only display classes found in the rules file.
        const classesInRules = findUsedClasses(rules);
        let classes = [];
        if(!!window.viewer.sceneData.classes) {
            for(const [class_id, class_values] of Object.entries(window.viewer.sceneData.classes)) {
                if(classesInRules.has(Number(class_id))) {
                    classes.push(<MenuItem value={class_id}>{class_values.name}</MenuItem>)
                }
            }
        }

        return <>
            <OverlayHeader onClose={this.props.onClose} title={"Clearance System"}/>
            <div className="overlay-content">
                <div id="clearance_canvas_container">
                    <canvas id="clearance_canvas" width={3360} height={1440}>
                    </canvas>
                </div>
                <div className="clearance_system_info_box">
                    <span> Where each square is </span>
                    <TextField
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        type="number"
                        value={this.state.square_side_meters}
                        onChange={(event) => {
                            let value = Number(event.target.value);
                            if(value <= 0) {
                                value = 1;
                            }

                            this.setState({
                                square_side_meters: value
                            });
                        }}
                        size="small"
                    />
                    <span> meter(s) on a side and classified as </span>
                    <Select
                        labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.classification}
                            label="Classification"
                            onChange={(event) => {
                                this.setState({
                                    classification: Number(event.target.value)
                                });
                            }}
                            size="small"
                            autoWidth
                    >
                        {classes}
                    </Select>
                </div>
                <div id="clearance_system_values">
                    <Tabs value={this.state.displayed_tab}>
                        <Tab label="Rules Json"/>
                    </Tabs>
                    <div hidden={this.state.displayed_tab !== 0}>
                        <pre className="code_style">
                            {JSON.stringify(window.viewer.clearance_rules, null, 4)}
                        </pre>
                    </div>
                </div>
            </div>
        </>
    }
}

export class OverlayMenu extends React.Component {
    renderContent() {
        switch(Number(this.props.active)) {
            case OverlayMenus.CLEARANCE:
                return <ClearanceSystemMenu onClose={this.props.onClose}/>;
            default:
                return <></>
        }
    }

    render() {
        return <div className="overlay-menu">
            {this.renderContent()}
        </div>
    }
}
