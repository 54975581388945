import React from "react";
import { Box, CircularProgress } from "@mui/material";

interface IProps {
  relative?: boolean;
  size?: string | number;
  color?: string;
}

function Loading({ relative, size = "inherit", color = "white" }: IProps) {
  let style: React.CSSProperties = !!relative
    ? {
        position: "relative",
        display: "grid",
        placeItems: "center",
      }
    : {
        position: "absolute",
        zIndex: 100,
        top: "50%",
        left: "50%",
        marginLeft: "-25px",
        marginTop: "-25px",
        display: "flex",
        borderRadius: "5px",
        padding: "5px",
      };

  return (
    <Box sx={style}>
      <CircularProgress sx={{ height: size, width: size, color }} size={size} />
    </Box>
  );
}

export default Loading;
