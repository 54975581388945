import { authorizedGet, authorizedPost } from "utils/request";
import { Color } from "./rendering/types";

export const FileFlags = {
  HAS_COLOR: 1 << 0,
  CLEARANCE: 1 << 1,
  SKYQRAFT_CLASSES: 1 << 2,
  LOCKED: 1 << 3
};

export const AccessLevel = {
    OWNER: 1,
    ADMIN: 2,
    SUPERVISOR: 3,
    ANNOTATOR: 4,
    CUSTOMER: 5,
    UPLOADER: 6,
    API: 7
};

export function getClearanceSystem(scene, signal) {
  return authorizedGet(
    `/potree/pointcloud/clearance_system/${scene}`,
    {},
    {
      signal: signal,
    }
  );
}
export function getSceneData(scene, signal) {
  return authorizedGet(
    `/potree/scene_data/${scene}`,
    {},
    {
      signal: signal,
    }
  );
}
export function getGlobalData() {
  return authorizedGet(
    "/potree/common_data"
  );
}
export function getProjectData() {
  return authorizedGet(
    "/potree/project_data"
  );
}
export function getAnnotations(scene, signal) {
  return authorizedGet(
    `/potree/annotations/${scene}`,
    {},
    {
      signal: signal,
    }
  );
}
export function postAnnotations(scene, removedVolumes, annotationVolumes, classifierVolumes) {
  const data = {
    "removed_volumes": removedVolumes,
    "annotations": annotationVolumes,
    "classifiers": classifierVolumes
  };

  return authorizedPost(
    `/potree/annotations/${scene}`,
    JSON.stringify(data)
  );
}
export function sendFeedback(
  scene,
  feedback,
  view,
  display_access_level
) {
  authorizedPost(`/potree/send_feedback/${scene}`, {
    feedback: feedback,
    position_x: view.position.x,
    position_y: view.position.y,
    position_z: view.position.z,
    pitch: view.pitch,
    yaw: view.yaw,
    display_access_level: display_access_level
  });
}

export function createClassificationScheme(
  sceneData
) {
  const scheme = {};

  for(const category of Object.values(sceneData.categories.entries)) {
    for(const key of category.entries) {
      const value = sceneData.classes[key];
      const class_color = new Color(value.color);

      scheme[key] = {
        visible: true,
        color: [class_color.r, class_color.g, class_color.b, 1.0],
        entries: value.scene_classes
      };
    }
  }

  return scheme;
}

export function createColorSchemeForClearanceAxis(
  displayedAxes,
  sceneData
  ) {
  let colorScheme = {}

  displayedAxes.forEach((displayedAxis) => {
    const axis = sceneData["clearance_system"]["axes"][displayedAxis];

    /*
    * For labels in to_labels:
    *   - Get color for index.
    *   For label in labels:
    *     - add color to colorScheme at label.
    */
    for(const [i, values] of axis["to_labels"].entries()) {
      const color = new Color(axis["colors"][i]);
      const color_as_array = [
        color.r,
        color.g,
        color.b,
        1.0
      ];

      for(const value of values) {
        colorScheme[value] = {
          color: color_as_array,
          visible: true
        };
      }
    }
  });

  return colorScheme;
}