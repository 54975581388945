import { resourcePath } from "potree/paths";
import React from "react";

export class MessageList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           messages: []
        };
    }

    componentDidMount() {
        window.viewer.postMessageHandler = this.addMessage.bind(this);
    }

    addMessage(message, isError, timeout) {
        //const closeIcon = `${resourcePath}/icons/close.svg`;

        // TODO: Make close button work :) Might require some other id.

        const messageElement = <div className={isError ? "potree_message_error" : "potree_message"}>
            <span name="content_container" style={{"flexGrow": "1", "padding": "5px"}}>{message}</span>
        </div>;
            //<img name="close" src={closeIcon} className="button-icon" style={{"width": "16px", "height": "16px"}} alt="Close"/>

        setTimeout(() => {
            this.setState({
                messages: this.state.messages.filter(a => a !== messageElement)
            });
        }, timeout);

        this.setState({
            messages: [
                ...this.state.messages,
                messageElement
            ]
        });
    }

    render() {
        return <>
            {this.state.messages}
        </>
    }
}